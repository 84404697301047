import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

class ContactPage extends React.Component {
  handleSubmit(event) {
    // do whatever you need here

    // if you need to stop the submit event and
    // perform/dispatch your own actions
    let recaptcha = document.querySelector("#g-recaptcha-response").value
    if (recaptcha === "") {
      event.preventDefault() //stop form from submitting
      alert("Please check the recaptcha")
    }
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteTagline = data.site.siteMetadata.description

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        tagline={siteTagline}
      >
        <Helmet>
          <script src="https://www.google.com/recaptcha/api.js"></script>
        </Helmet>
        <SEO
          title="Contact"
          keywords={["blog", "gatsby", "javascript", "react"]}
        />
        <div id="main" className="alt">
          <section id="one">
            <div className="inner">
              <header className="major">
                <h1>Contact Me</h1>
              </header>
              <p>Have any questions or comments, let me know.</p>
              <section id="contact">
                <div className="inner">
                  <section>
                    <form
                      id="contactForm"
                      name="contact"
                      method="post"
                      action="/success"
                      data-netlify-recaptcha="true"
                      data-netlify-honeypot="bot-field"
                      data-netlify="true"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="field half first">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name" required />
                      </div>
                      <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email" required />
                      </div>
                      <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea
                          name="message"
                          id="message"
                          rows="6"
                          required
                        ></textarea>
                      </div>
                      <div>
                        <input type="hidden" name="form-name" value="contact" />
                        <input type="hidden" name="bot-field" />
                      </div>
                      <div
                        className="g-recaptcha"
                        data-sitekey="6Ldq8YUUAAAAAM5Ae_tGmfTqgiz3r5m_iRGWiavW"
                      ></div>
                      <div className="actions">
                        <input
                          type="submit"
                          value="Send Message"
                          className="special"
                        />
                      </div>
                    </form>
                  </section>
                </div>
              </section>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
